import React from 'react';
import Header from './components-global/Header';

const PrivacyPolicy = () => (
    <div>
        <Header alwaysVisible={true} />
        <div className="container p-5 page-content">
            <div className="privacy-policy-container">
                <h1 style={{ color: '#216AD9' }}>Privacy Policy</h1>
                
                <h2 style={{ color: '#216AD9' }}>Introduction</h2>
                <p>Welcome to AI Bunny. Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services. By using our services, you agree to the collection and use of your data in accordance with this policy.</p>

                <h2 style={{ color: '#216AD9' }}>Article 1 - Definitions</h2>
                <p>
                    <strong>Website:</strong> www.aibunny.tech<br />
                    <strong>Controller:</strong> AI Bunny, established at 6545 Market Ave. North STE 100, North Canton, OH 44721<br />
                    <strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.<br />
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 2 - Access to the Website</h2>
                <p>
                    Access to and use of the website are strictly personal. You will refrain from using the data and information of this website for your own commercial, political, or advertising purposes, as well as for any commercial offers, particularly unsolicited electronic offers.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 3 - Website Content</h2>
                <p>
                    All brands, images, texts, comments, illustrations, video images, sounds, and technical applications used to operate this website, and more generally all components used on this website, are protected by intellectual property laws. Any reproduction, repetition, use, or modification, by any means whatsoever, of all or part of it, including technical applications, without prior written permission from the controller, is strictly prohibited.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 4 - Management of the Website</h2>
                <p>
                    The controller reserves the right to:
                </p>
                <ul>
                    <li>Suspend, interrupt, reduce, or deny access to the website for specific visitors.</li>
                    <li>Delete all information that disrupts the functioning of the website, violates laws, or contradicts internet etiquette.</li>
                    <li>Make the website temporarily unavailable to perform updates or maintenance.</li>
                </ul>

                <h2 style={{ color: '#216AD9' }}>Article 5 - Information We Collect</h2>
                <p>
                    • <strong>Personal Identification Information:</strong> Name, address, phone number.<br />
                    • <strong>Technical Data:</strong> IP address, browser type, operating system, and usage data.<br />
                    • <strong>Cookies and Tracking Technologies:</strong> To enhance user experience and analyze site traffic.<br />
                    • <strong>Customer Interaction Data:</strong> Data collected through AI Agents (e.g., phone number, address, name) for business use on platforms like Google Chat, Phone Call, and Meta Business products (Instagram API, WhatsApp Business API), and similar tools. In addition to phone number, address, name, and other similar data, we may collect call recordings and transcriptions generated during customer interactions. These recordings are used to enhance service accuracy, training, and dispute resolution.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 6 - How We Use Your Information</h2>
                <p>
                We use your personal information to provide and improve our services. This includes processing transactions, communicating with you, and tailoring our offerings. Our services are designed to support businesses such as restaurants, pizza stores, law firms, car dealerships, and other industries requiring customer relationship management. When you interact with our phone agents or other services, we may collect and process data on behalf of these businesses. All data collected is used solely to provide the requested services and is not shared outside the scope of this purpose.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 7 - Data Sharing with Third Parties</h2>
                <p>
                    We use Google Cloud Firestore Database services to store and manage your data securely. We do not sell, trade, or rent your personal information to others. However, we use third-party APIs and services to enhance the functionality of our platform.<br />
                    These providers include, but are not limited to, 11Labs, OpenAI, Ollama, Google Speech-to-Text, PlayHT, Cartesia, Rime AI, and Google Maps. These services may process data such as voice inputs, text conversations, and location details. While we ensure these providers comply with industry-standard data protection practices, their services are also governed by their privacy policies, which we encourage you to review.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 8 - Data Security Measures</h2>
                <p>
                    We prioritize the security of your data by implementing encryption, secure servers, access controls, and regular audits. Despite our best efforts, no system is completely secure, and we cannot guarantee absolute data protection.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 9 - User Rights</h2>
                <p>
                    You have the right to access, correct, delete, or restrict the processing of your data, as well as the right to data portability. To exercise these rights, contact us at <a href="mailto:aiagent@aibunny.tech">aiagent@aibunny.tech</a>. Include a copy of your ID and your signature. We will respond within one month, extendable to two months if necessary.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 10 - Cookies and Tracking</h2>
                <p>
                    • <strong>Analytic Cookies:</strong> Used to analyze traffic and improve communication.<br />
                    • <strong>Tracking Cookies:</strong> Used to show relevant advertisements and create personalized experiences. These cookies may process personal data and allow third-party organizations to show targeted ads.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 11 - Managing Cookies</h2>
                <p>
                    You can manage your cookie preferences through your browser settings. Refer to your browser's help section for details.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 12 - Data Retention</h2>
                <p>
                    We retain data for as long as necessary to fulfill our obligations and comply with legal requirements.
                    Retention of Call Recordings: Call recordings and related data are retained for up to 30 days, unless a longer retention period is required for legal purposes, dispute resolution, or customer support. After this period, recordings are securely deleted.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 13 - Policy Changes</h2>
                <p>
                    We will notify you about changes to this privacy policy via email or through notifications on our website.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 14 - Contact Information</h2>
                <p>
                    For questions or concerns, contact us at:<br />
                    • Email: <a href="mailto:info@aibunny.tech">info@aibunny.tech</a>
                </p>
            </div>
        </div>
    </div>
);

export default PrivacyPolicy;