import React from 'react';
import Header from './components-global/Header';

const TermsOfService = () => (
    <div>
        <Header alwaysVisible={true} />
        <div className="container p-5 page-content">
            <div className="terms-of-service-container">
                <h1 style={{ color: '#216AD9' }}>Terms of Service</h1>
                
                <h2 style={{ color: '#216AD9' }}>Introduction</h2>
                <p>Welcome to AI Bunny. By accessing or using our website and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>

                <h2 style={{ color: '#216AD9' }}>Article 1 - Definitions</h2>
                <p>
                    <strong>Website:</strong> www.aibunny.tech<br />
                    <strong>Services:</strong> The AI-driven solutions and services provided by AI Bunny, including AI Agents, phone agents, and chatbot integrations.<br />
                    <strong>User:</strong> Anyone who accesses or uses the Website and Services.<br />
                    <strong>Content:</strong> All information, text, graphics, or other materials uploaded, downloaded, or appearing on the Website or through the Services.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 2 - Use of Services</h2>
                <p>
                    Users must comply with all applicable laws and regulations while using the Services.<br />
                    Users must not use the Services for any unlawful or unauthorized purpose, including but not limited to distributing harmful or illegal content or engaging in fraudulent activities.<br />
                    Users acknowledge that Services provided by AI Bunny may be used to interact with third-party businesses, such as restaurants or service providers, and that AI Bunny is not responsible for the actions or services of these third-party businesses.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 3 - User Accounts</h2>
                <p>
                    Users may need to create an account to access certain features of the Services.<br />
                    Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.<br />
                    Users must immediately notify AI Bunny of any unauthorized use of their account or any other breach of security.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 4 - Content Ownership and Rights</h2>
                <p>
                    All content on the Website and Services is the property of AI Bunny or its licensors and is protected under applicable copyright and intellectual property laws.<br />
                    Users retain ownership of any content they post but grant AI Bunny a worldwide, non-exclusive, royalty-free license to use, reproduce, and display such content for purposes related to the operation and improvement of the Services.<br />
                    Users are solely responsible for ensuring that any content they upload does not infringe on the rights of third parties.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 5 - Privacy</h2>
                <p>
                    Your use of the Services is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which describes how we collect, use, and protect your personal information.<br />
                    By using our services, you consent to the collection, storage, and use of call recordings as described in our Privacy Policy. Call recordings may be used for quality assurance, training, dispute resolution, and service improvement.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 6 - Intellectual Property</h2>
                <p>
                    Users must not reproduce, distribute, or create derivative works from any content on the Website or provided through the Services without prior written consent from AI Bunny.<br />
                    Users are prohibited from using any of AI Bunny’s trademarks, logos, or branding without explicit permission.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 7 - Third-Party Services</h2>
                <p>
                    AI Bunny integrates third-party services such as APIs from OpenAI, 11Labs, Google Maps, and others to provide and enhance its offerings.<br />
                    While we ensure these services comply with industry standards, we are not responsible for the data processing practices or actions of these third parties. Users are encouraged to review the terms and policies of any third-party services utilized in conjunction with AI Bunny's Services.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 8 - Termination</h2>
                <p>
                    AI Bunny reserves the right to terminate or suspend access to the Services for any reason, including but not limited to violation of these Terms, without prior notice.<br />
                    Upon termination, any licenses granted under these Terms will immediately cease, and Users must stop using the Services.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 9 - Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, AI Bunny shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, arising from or related to the use of the Services.<br />
                    AI Bunny’s total liability for any claim arising out of or relating to these Terms or the Services shall not exceed the amount paid by the User to AI Bunny for the Services in the 12 months preceding the claim.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 10 - Changes to Terms</h2>
                <p>
                    AI Bunny reserves the right to modify these Terms at any time. Users will be notified of significant changes through the Website, email, or other appropriate means. Continued use of the Services after changes take effect constitutes acceptance of the updated Terms.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 11 - Governing Law</h2>
                <p>
                    These Terms are governed by the laws of the State of Ohio, without regard to its conflict of law principles.<br />
                    Any disputes arising out of or related to these Terms or the Services shall be resolved exclusively in the courts located in Ohio.
                </p>

                <h2 style={{ color: '#216AD9' }}>Article 12 - Contact Information</h2>
                <p>
                    For questions about these Terms of Service, please contact:<br />
                    • Email: <a href="mailto:info@aibunny.tech">info@aibunny.tech</a>
                </p>
            </div>
        </div>
    </div>
);

export default TermsOfService;